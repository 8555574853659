export const formatFileBase64 = (value) => {
  if (!value) return null;

  const png = 'data:image/png;base64,';
  const jpg = 'data:image/jpg;base64,';
  const jpeg = 'data:image/jpeg;base64,';
  const pdf = 'data:application/pdf;base64,';

  switch (value) {
    case value.includes('.png'):
      return `${png}${value}`;
    case value.includes('.jpeg'):
      return `${jpeg}${value}`;
    case value.includes('.jpg'):
      return `${jpg}${value}`;
    case value.includes('.pdf'):
      return `${pdf}${value}`;
    default:
      return `${png}${value}`;
  }
};

export function returnOnlyNumber(value, type) {
  if (type === 'number')
    return value.toString().includes('-')
      ? -Number(value.toString().replace(/\D/g, ''))
      : Number(value.toString().replace(/\D/g, ''));
  return value.toString().replace(/\D/g, '');
}

export function validationCPF(cpf) {
  let sum = 0;
  let remainder;

  const strCPF = String(cpf).replace(/[^\d]/g, '');
  if (strCPF.length !== 11) return false;

  if (
    [
      '00000000000',
      '11111111111',
      '22222222222',
      '33333333333',
      '44444444444',
      '55555555555',
      '66666666666',
      '77777777777',
      '88888888888',
      '99999999999',
    ].indexOf(strCPF) !== -1
  )
    return false;

  for (let i = 1; i <= 9; i++)
    sum = sum + parseInt(strCPF.substring(i - 1, i)) * (11 - i);

  remainder = (sum * 10) % 11;

  if (remainder === 10 || remainder === 11) remainder = 0;
  if (remainder !== parseInt(strCPF.substring(9, 10))) return false;

  sum = 0;

  for (let i = 1; i <= 10; i++)
    sum = sum + parseInt(strCPF.substring(i - 1, i)) * (12 - i);

  remainder = (sum * 10) % 11;

  if (remainder === 10 || remainder === 11) remainder = 0;
  if (remainder !== parseInt(strCPF.substring(10, 11))) return false;

  return true;
}

export const oraganizeArrayByParam = (array, param = 'name') => {
  return array?.sort(function (a, b) {
    return a[param] > b[param] ? 1 : b[param] > a[param] ? -1 : 0;
  });
};

export const calculateAge = (birthdate) => {
  const birthDate = new Date(birthdate + 'T00:00:00');
  const today = new Date();
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDifference = today.getMonth() - birthDate.getMonth();

  if (
    monthDifference < 0 ||
    (monthDifference === 0 && today.getDate() < birthDate.getDate())
  ) {
    age--;
  }

  return age;
};

export const getTodayDate = () => new Date().toISOString().split('T')[0];

export const isNotEmpty = (value) => {
  return (
    value !== '' && value !== null && value !== undefined && value.trim() !== ''
  );
};

export const generateUUID = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};
