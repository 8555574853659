import { isAxiosError } from 'axios';
import {
  LOCAL_FILE_PATIENT_API,
  LOCAL_FILE_PATIENTS_API,
} from './local_file_patients';
import customAxios from '../axiosCustom';
import errorStatusCode from 'utils/errorStatusCode';
import URL from '../api_url';

class LocalFilePatientsService {
  create = async (uuid, formData, setLoadingValue) => {
    try {
      const response = await customAxios.post(
        `${LOCAL_FILE_PATIENT_API}/patient/${uuid}`,
        formData,
        {
          onUploadProgress: (event) => {
            const progress = Math.round((event.loaded * 100) / event.total);
            setLoadingValue(progress - 1);
          },
        }
      );
      setLoadingValue(100);

      if (!response) new Error('Não foi possível criar arquivo');

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response.status;

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          default:
            throw new Error('Não foi possível criar arquivo');
        }
      }

      throw new Error(error);
    }
  };

  read = async (uuid) => {
    try {
      const response = await customAxios.get(
        `${LOCAL_FILE_PATIENT_API}/${uuid}`
      );

      if (!response) new Error('Não foi possível carregar arquivo');

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          default:
            throw new Error('Não foi possível carregar arquivo');
        }
      }

      throw new Error(error);
    }
  };

  list = async (filter = '') => {
    try {
      const response = await customAxios.get(
        `${LOCAL_FILE_PATIENTS_API}${filter}`
      );

      if (!response) new Error('Não foi possível listar arquivos');

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          default:
            throw new Error('Não foi possível listar arquivos');
        }
      }

      throw new Error(error);
    }
  };

  edit = async (uuid, formData) => {
    try {
      const response = await customAxios.put(
        `${LOCAL_FILE_PATIENT_API}/${uuid}`,
        formData
      );

      if (!response) new Error('Não foi possível editar arquivo');

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response.status;

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          default:
            throw new Error('Não foi possível editar arquivo');
        }
      }

      throw new Error(error);
    }
  };

  delete = async (uuid) => {
    try {
      const response = await customAxios.delete(
        `${LOCAL_FILE_PATIENT_API}/${uuid}`
      );

      if (!response) new Error('Não foi possível apagar arquivo');

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response.status;

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          default:
            throw new Error('Não foi possível apagar arquivo');
        }
      }

      throw new Error(error);
    }
  };

  getFile = async (uuid) => {
    try {
      const response = await customAxios.get(
        `${LOCAL_FILE_PATIENT_API}/${uuid}`
      );
      const link = document.createElement('a');
      link.href = `${URL}${response.data.downloadUrl}`;
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      throw new Error('Não foi possível fazer download do arquivo');
    }
  };
}

export default new LocalFilePatientsService();
