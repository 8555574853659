import { useFormikContext } from 'formik';
import { useEffect } from 'react';

export function ScrollToError() {
  const { touched, errors, isSubmitting } = useFormikContext();

  useEffect(() => {
    const errorFieldNames = Object.keys(errors).filter(
      (name) => touched[name] && errors[name]
    );
    if (errorFieldNames.length > 0) {
      const firstErrorField = document.querySelector(
        `[data-error="${errorFieldNames[0]}"]`
      );
      if (firstErrorField) {
        firstErrorField.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center',
        });
      }
    }
  }, [isSubmitting]);

  return null;
}
