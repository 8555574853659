import PropTypes from 'prop-types';

import { ErrorMessage, Field } from 'formik';

import MDBox from 'shared/components/MDBox';
import MDTypography from 'shared/components/MDTypography';

import { Checkbox, useMediaQuery, useTheme, Avatar } from '@mui/material';

function FormFieldCheckboxIcon({
  name,
  value,
  options,
  fieldVariant,
  disabledValue,
  ...rest
}) {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const uncheckedIconStyle = {
    bgcolor: theme.palette.white.main,
    border: `1px solid ${theme.palette.secondary.main}`,
    color: theme.palette.secondary.main,
    width: 36,
    height: 36,
  };

  const checkedIconStyle = {
    bgcolor: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.white.main,
    width: 36,
    height: 36,
  };

  const uncheckedDisabledIconStyle = {
    bgcolor: theme.palette.grey[400],
    border: `1px solid ${theme.palette.secondary.main}`,
    color: theme.palette.secondary.main,
    width: 36,
    height: 36,
  };

  const checkedDisabledIconStyle = {
    bgcolor: theme.palette.grey[400],
    border: `1px solid ${theme.palette.primary.focus}`,
    color: theme.palette.white.main,
    width: 36,
    height: 36,
  };

  const disableField = (value) => {
    if (disabledValue === 'all') return true;
    if (disabledValue === value) return true;
    return false;
  };

  return (
    <MDBox
      display='flex'
      // flexDirection={smallScreen ? "column" : "row"}
      alignItems={smallScreen ? 'flex-start' : 'center'}
      flexWrap='wrap'
    >
      {options?.map((val, i, arr) => (
        <MDBox
          display='flex'
          alignItems='center'
          justifyContent='center'
          key={i}
        >
          <Field
            {...rest}
            type='checkbox'
            disabled={disableField(val.value)}
            name={name}
            as={Checkbox}
            checked={value.includes(val.value)}
            value={val.value}
            icon={
              <Avatar
                sx={
                  disableField(val.value)
                    ? uncheckedDisabledIconStyle
                    : uncheckedIconStyle
                }
              >
                {val.label}
              </Avatar>
            }
            checkedIcon={
              <Avatar
                sx={
                  disableField(val.value)
                    ? checkedDisabledIconStyle
                    : checkedIconStyle
                }
              >
                {val.label}
              </Avatar>
            }
            data-error={name}
          />
        </MDBox>
      ))}

      <MDBox>
        <MDTypography
          component='div'
          variant='caption'
          color='error'
          fontWeight='regular'
          align='center'
          m={0.75}
        >
          <ErrorMessage name={name} />
        </MDTypography>
      </MDBox>
    </MDBox>
  );
}

FormFieldCheckboxIcon.defaultProps = {
  //   label: "",
  options: [],
  fieldVariant: 'standard',
  disabledValue: '',
};

FormFieldCheckboxIcon.propTypes = {
  //   label: PropTypes.string,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.any),
  fieldVariant: PropTypes.string,
  disabledValue: PropTypes.string,
};

export default FormFieldCheckboxIcon;
