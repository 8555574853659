import PropTypes from 'prop-types';

import { ErrorMessage, FastField } from 'formik';

import MDBox from 'shared/components/MDBox';
import MDTypography from 'shared/components/MDTypography';
import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { memo } from 'react';

function FormFieldRadio({
  name,
  value,
  options,
  fieldVariant,
  direction,
  validationDirection,
  ...rest
}) {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleDirection = (component, type) => {
    if (component === 'Box') {
      if (type === 'flexDirection') {
        if (validationDirection === 'column') return 'column';
        if (smallScreen && direction === 'row') return 'column';
        return direction;
      } else if (type === 'alignItems') {
        return 'flex-start';
      }
    } else if (component === 'RadioGroup') {
      if (type === 'flexDirection') {
        if (smallScreen && direction === 'row') return 'column';
        else return direction;
      } else if (type === 'alignItems') {
        if (smallScreen && direction === 'row') return 'flex-start';
        else return 'flex-start';
      }
    }
    return null;
  };

  return (
    <MDBox
      display='flex'
      flexDirection={handleDirection('Box', 'flexDirection')}
      alignItems={handleDirection('Box', 'alignItems')}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl component='fieldset'>
            <FastField {...rest} name={name} data-error={name}>
              {({ field }) => (
                <RadioGroup
                  name={name}
                  value={field.value}
                  onChange={field.onChange}
                  row={true}
                  sx={{
                    display: 'flex',
                    flexDirection: handleDirection(
                      'RadioGroup',
                      'flexDirection'
                    ),
                    alignItems: handleDirection('RadioGroup', 'alignItems'),
                  }}
                >
                  {options?.map((val, i, arr) => (
                    <FormControlLabel
                      {...rest}
                      value={val.value}
                      control={<Radio />}
                      label={
                        <MDTypography variant='body2'>{val.label}</MDTypography>
                      }
                      key={i}
                    />
                  ))}
                </RadioGroup>
              )}
            </FastField>
          </FormControl>
        </Grid>
      </Grid>

      <MDBox>
        <MDTypography
          component='div'
          variant='caption'
          color='error'
          fontWeight='regular'
          align='center'
          m={0.75}
        >
          <ErrorMessage name={name} />
        </MDTypography>
      </MDBox>
    </MDBox>
  );
}

FormFieldRadio.defaultProps = {
  //   label: "",
  options: [],
  fieldVariant: 'standard',
  direction: 'row',
  validationDirection: 'row',
};

FormFieldRadio.propTypes = {
  //   label: PropTypes.string,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.any),
  fieldVariant: PropTypes.string,
  direction: PropTypes.string,
  validationDirection: PropTypes.string,
};

export default memo(FormFieldRadio);
