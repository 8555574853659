const initialValues = {
  name: '',
  treatment_started: '',
  birthdate: '',
  age: '',
  email: '',
  phone: '',
  school: '',
  schooling: '',
  school_term: '',
  father_name: '',
  father_age: '',
  father_birthdate: '',
  father_job: '',
  mother_name: '',
  mother_age: '',
  mother_birthdate: '',
  mother_job: '',
  brothers: '',
  cep: '',
  address: '',
  number: '',
  complement: '',
  neighborhood: '',
  city: '',
  state: '',
  forwarded_by: '',
  accompanying_doctor: '',
  health_insurance: '',
  service_hours: '',
  best_service_time: '',
  status: 1,
  supervisor_uuid: '',
  therapist_uuids: [],
};

export const initialValuesFile = {
  file: '',
  type: '',
};

export const initialValuesAnamnesisToys = {
  name: '',
};

export const initialValuesAnamnesisEdibles = {
  name: '',
};

export const initialValuesAnamnesis = {
  health_insurance: '',
  reference: '',
  diagnosis: '',
  diagnosis_description: '',
  complaint: '',
  personal_background: '',
  accompaniments: '',
  gestation: '',
  birth_type: '',
  premature: '',
  gestational_time: '',
  needed_oxygen: '',
  needed_oxygen_howlong: '',
  born_cyanotic: '',
  cried_at_birth: '',
  needed_incubator: '',
  needed_incubator_howlong: '',
  neonatal_history: '',

  breastfed: '',
  weaning_age: '',
  dietary_restrictions: '',
  describe_dietary_restrictions: '',
  food_selectivity: '',
  describe_food_selectivity: '',

  sat_age: '',
  crawled_age: '',
  walked_age: '',
  first_words_age: '',
  speech_regression: '',
  speech_regression_age: '',
  random_sounds: '',
  short_phrases: '',
  functional_speech: '',
  presence_echolalia: '',
  aim: '',
  perseverative_speech: '',
  intention_of_communicating: '',
  complex_phrases: '',
  understandable_phrases: '',
  idiosyncratic_language: '',
  tacts: '',
  order: '',
  sphincter_control: '',
  sphincter_control_age: '',
  psychomotor_observations: '',

  sleep_well: '',
  sleeping_medicine: '',
  dream_observations: '',
  sleep_routine: '',

  tic: '',
  tic_frequency: '',
  tic_observations: '',
  tic_occurrence: '',
  tic_family_reaction: '',

  stereotyped_behaviors: '',
  stereotyped_behaviors_frequency: '',
  stereotyped_behaviors_observations: '',
  stereotyped_behaviors_occurrence: '',
  stereotyped_behaviors_family_reaction: '',

  selfinjurious_behaviors: '',
  selfinjurious_behaviors_frequency: '',
  selfinjurious_behaviors_observations: '',
  selfinjurious_behaviors_occurrence: '',
  selfinjurious_behaviors_family_reaction: '',
  selfinjurious_behavioral_triggers: '',

  heteroaggressive_behaviors: '',
  heteroaggressive_behaviors_frequency: '',
  heteroaggressive_behaviors_observations: '',
  heteroaggressive_behaviors_occurrence: '',
  heteroaggressive_behaviors_family_reaction: '',
  heteroaggressive_behavioral_triggers: '',

  tantrum_crisis: '',
  tantrum_crisis_frequency: '',
  tantrum_crisis_observations: '',
  tantrum_crisis_occurrence: '',
  tantrum_crisis_family_reaction: '',
  tantrum_behavioral_triggers: '',

  rituals: '',
  rituals_frequency: '',
  rituals_observations: '',
  rituals_occurrence: '',
  rituals_family_reaction: '',

  hyperactive_behaviors: '',
  hyperactive_behaviors_observations: '',
  hyperactive_behaviors_occurrence: '',
  hyperactive_behaviors_family_reaction: '',

  oppositional_behaviors: '',
  oppositional_behaviors_observations: '',
  oppositional_behaviors_occurrence: '',
  oppositional_behaviors_family_reaction: '',

  sensory_changes: '',
  sensory_changes_type: '',
  sensory_changes_observations: '',
  sensory_changes_occurrence: '',
  sensory_changes_family_reaction: '',

  family_history: '',
  family_child_behavior_impacts: '',
  family_quality_of_life: '',
  family_child_behavior_with_others: '',
  family_person_with_difficulties: '',
  family_primary_caregiver_at_home: '',
  family_child_has_nanny: '',

  sociability_around_other_children: '',
  sociability_pranks_classmates: '',
  sociability_seated_play_group: '',
  sociability_play_alone: '',
  sociability_physical_contact: '',
  sociability_eye_contact: '',
  sociability_observations: '',

  education_entry_age: '',
  education_how_react: '',
  education_engage_activities: '',
  education_seated_lunch_without_leave: '',
  education_support_teacher: '',
  education_teacher_name: '',
  education_literate: '',
  education_observations: '',

  play_skills_free_time: '',
  play_skills_favorite_toys: '',
  play_skills_engaged_playing: '',
  play_skills_alone_or_group: '',
  play_skills_play_body: '',
  play_skills_play_body_how_long: '',
  play_skills_functionally_or_toys: '',
  play_skills_observations: '',
  play_skills_how_child_uses_toys: '',

  weekly_routine_monday: '',
  weekly_routine_tuesday: '',
  weekly_routine_wednesday: '',
  weekly_routine_thursday: '',
  weekly_routine_friday: '',
  weekly_routine_saturday: '',
  weekly_routine_sunday: '',

  family_view_on_child_autonomy: '',
  expected_outcomes_of_aba_intervention: '',

  toys: [
    initialValuesAnamnesisToys,
    initialValuesAnamnesisToys,
    initialValuesAnamnesisToys,
    initialValuesAnamnesisToys,
  ],
  edibles: [
    initialValuesAnamnesisEdibles,
    initialValuesAnamnesisEdibles,
    initialValuesAnamnesisEdibles,
    initialValuesAnamnesisEdibles,
  ],
  preference_items_others: '',
  preference_items_observations: '',

  // new fields

  // personal data
  name: '',
  birthdate: '',
  age: '',
  schooling: '',
  school_term: '',
  school: '',
  father_name: '',
  father_birthdate: '',
  father_job: '',
  mother_name: '',
  mother_birthdate: '',
  mother_job: '',
  parents_live_together: '',
  child_residence: '',
  child_guardian: '',
  both_active_in_care: '',
  pre_set_visitation_schedule: '',
  parent_relationship: '',
  siblings: '',
  contact_phones: '',
  address: '',
  forwarded_by: '',
  accompanying_doctor: '',
  uses_medication: '',
  which_medications: '',

  // hyperactive_behaviors_occurrence: '',
  // hyperactive_behaviors_family_reaction: '',
  // oppositional_behaviors_occurrence: '',
  // oppositional_behaviors_family_reaction: '',
  // sensory_changes_occurrence: '',
  // sensory_changes_family_reaction: '',

  // // Família
  // family_history: '',
  // family_child_behavior_impacts: '',
  // family_quality_of_life: '',
  // family_child_behavior_with_others: '',
  // family_person_with_difficulties: '',
  // family_primary_caregiver_at_home: '',
  // family_child_has_nanny: '',

  // // Rotina diária/semanal
  // weekly_routine_monday: '',
  // weekly_routine_tuesday: '',
  // weekly_routine_wednesday: '',
  // weekly_routine_thursday: '',
  // weekly_routine_friday: '',
  // weekly_routine_saturday: '',
  // weekly_routine_sunday: '',

  // // Independência & Expectativas
  // family_view_on_child_autonomy: '',
  // expected_outcomes_of_aba_intervention: '',

  date: '',
};

export const initialValuesMeeting = {
  participants: '',
  description: '',
  school: '',
  patient_uuid: '',
  type: '',
  date: '',
  current_therapist: '',
};

// programs
export const initialValuesObjectives = { name: '', uuid: '' };

export const initialValuesPrograms = {
  name: '',
  application_phase: '',
  status: '',
  procedure: '',
  criterion: '',
  materials: '',
  suggested_reinforcer: '',
  attemps_number: '',
  patient_uuid: '',
  objectives: [initialValuesObjectives],
  supervisor_uuid: '',
};

export const initialValuesBehaviors = {
  name: '',
  application_phase: '',
  status: 1,
  procedure: '',
  register_type: '',
  patient_uuid: '',
  supervisor_uuid: '',
};

export default initialValues;
