import { isAxiosError } from 'axios';
import { PATIENT_API, PATIENTS_API } from './patients';
import customAxios from '../axiosCustom';
import errorStatusCode from 'utils/errorStatusCode';
import ReactDOMServer from 'react-dom/server';

const errorMsgHttp = (data, defaultMsg) => {
  if (typeof data === 'string') {
    return data;
    // switch (data) {
    //   case "name already in use":
    //     return "Nome ja está em uso.";

    //   case "Supervisor já tem 30 pacientes":
    //     return "Supervisor já tem 30 pacientes";

    //   default:
    //     return defaultMsg;
    // }
  } else {
    const element = (
      <div>
        {data.map((msg, i) => (
          <p key={i}>{msg}</p>
        ))}
      </div>
    );
    const jsxString = ReactDOMServer.renderToString(element);
    return jsxString;
  }
};

class PatientsService {
  create = async ({
    name,
    treatment_started,
    birthdate,
    email,
    phone,
    school,
    schooling,
    school_term,
    father_name,
    father_age,
    father_birthdate,
    father_job,
    mother_name,
    mother_age,
    mother_birthdate,
    mother_job,
    brothers,
    cep,
    address,
    number,
    complement,
    neighborhood,
    city,
    state,
    forwarded_by,
    accompanying_doctor,
    health_insurance,
    service_hours,
    supervisor_uuid,
    therapist_uuids,
    best_service_time,
    age,
  }) => {
    try {
      const response = await customAxios.post(PATIENT_API, {
        name,
        treatment_started,
        birthdate,
        email,
        phone,
        school,
        schooling,
        school_term,
        father_name,
        father_age,
        father_birthdate,
        father_job,
        mother_name,
        mother_age,
        mother_birthdate,
        mother_job,
        brothers,
        cep,
        address,
        number,
        complement,
        neighborhood,
        city,
        state,
        forwarded_by,
        accompanying_doctor,
        health_insurance,
        service_hours,
        supervisor_uuid,
        therapist_uuids,
        best_service_time,
        age,
      });

      if (!response) new Error('Não foi possível criar paciente');

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response.status;
        const defaultMsg = 'Não foi possível criar paciente';

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          case 409:
            throw new Error(error.response?.data);

          case 422:
            throw new Error(errorMsgHttp(error.response?.data, defaultMsg));

          default:
            throw new Error(defaultMsg);
        }
      }

      throw new Error(error);
    }
  };

  read = async (uuid) => {
    try {
      const response = await customAxios.get(`${PATIENT_API}/${uuid}`);

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          case 404:
            throw new Error(error.response?.data);

          default:
            throw new Error('Não foi possível carregar paciente');
        }
      }

      throw new Error(error);
    }
  };

  list = async (filter = '') => {
    try {
      const response = await customAxios.get(`${PATIENTS_API}${filter}`);

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          case 404:
            throw new Error(error.response?.data);

          default:
            throw new Error('Não foi possível listar usuários');
        }
      }

      throw new Error(error);
    }
  };

  edit = async (
    uuid,
    {
      name,
      treatment_started,
      birthdate,
      email,
      phone,
      school,
      schooling,
      school_term,
      father_name,
      father_age,
      father_birthdate,
      father_job,
      mother_name,
      mother_age,
      mother_birthdate,
      mother_job,
      brothers,
      cep,
      address,
      number,
      complement,
      neighborhood,
      city,
      state,
      forwarded_by,
      accompanying_doctor,
      health_insurance,
      service_hours,
      status,
      supervisor_uuid,
      therapist_uuids,
      best_service_time,
      age,
    }
  ) => {
    try {
      const response = await customAxios.put(`${PATIENT_API}/${uuid}`, {
        name,
        treatment_started,
        birthdate,
        email,
        phone,
        school,
        schooling,
        school_term,
        father_name,
        father_age,
        father_birthdate,
        father_job,
        mother_name,
        mother_age,
        mother_birthdate,
        mother_job,
        brothers,
        cep,
        address,
        number,
        complement,
        neighborhood,
        city,
        state,
        forwarded_by,
        accompanying_doctor,
        health_insurance,
        service_hours,
        status,
        supervisor_uuid,
        therapist_uuids,
        best_service_time,
        age,
      });

      if (!response) new Error('Não foi possível editar paciente');

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response.status;
        const defaultMsg = 'Não foi possível editar paciente';

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          case 409:
            throw new Error(error.response?.data);

          case 422:
            throw new Error(errorMsgHttp(error.response?.data, defaultMsg));

          default:
            throw new Error(defaultMsg);
        }
      }

      throw new Error(error);
    }
  };

  updateStatus = async ({ uuid, status }) => {
    try {
      const response = await customAxios.patch(`${PATIENT_API}/${uuid}`, {
        status,
      });

      if (!response) new Error('Não foi possível editar paciente');

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;
        const defaultMsg = 'Não foi possível editar paciente';
        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          case 404:
            throw new Error(errorMsgHttp(error.response?.data, defaultMsg));

          case 422:
            throw new Error(errorMsgHttp(error.response?.data, defaultMsg));

          default:
            throw new Error(defaultMsg);
        }
      }

      throw new Error(error);
    }
  };

  delete = async (uuid) => {
    try {
      const response = await customAxios.delete(`${PATIENT_API}/${uuid}`);

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response.status;
        const defaultMsg = 'Não foi possível apagar paciente';

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          case 404:
            throw new Error(error.response?.data);

          case 422:
            throw new Error(error.response?.data);

          default:
            throw new Error(defaultMsg);
        }
      }

      throw new Error(error);
    }
  };

  listRelationships = async (filter = '') => {
    try {
      const response = await customAxios.get(
        `${PATIENT_API}/supervisors-or-therapists${filter}`
      );

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          case 404:
            throw new Error(error.response?.data);

          default:
            throw new Error('Não foi possível listar usuários');
        }
      }

      throw new Error(error);
    }
  };
}

export default new PatientsService();
