import { isAxiosError } from 'axios';
import { USER_API, USERS_API } from './users';
import customAxios from '../axiosCustom';
import errorStatusCode from 'utils/errorStatusCode';
import ReactDOMServer from 'react-dom/server';

const errorMsgHttp = (data, defaultMsg) => {
  if (typeof data === 'string') {
    return data;
  } else {
    const element = (
      <div>
        {data.map((msg, i) => (
          <p key={i}>{msg}</p>
        ))}
      </div>
    );
    const jsxString = ReactDOMServer.renderToString(element);
    return jsxString;
  }
};

class UsersService {
  create = async ({
    role,
    name,
    phone,
    email,
    emergency_contact,
    professional_role,
    admissiondate,
    rpa_number_registration,
    cep,
    address,
    number,
    complement,
    neighborhood,
    city,
    state,
    birthdate,
    document,
    supervisor_uuids,
  }) => {
    try {
      const response = await customAxios.post(USER_API, {
        role,
        name,
        phone,
        email,
        emergency_contact,
        professional_role,
        admissiondate,
        rpa_number_registration,
        cep,
        address,
        number,
        complement,
        neighborhood,
        city,
        state,
        birthdate,
        document,
        supervisor_uuids,
      });

      if (!response) new Error('Não foi possível criar usuário');

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response.status;
        const defaultMsg = 'Não foi possível criar usuário';
        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          case 403:
            throw new Error(error.response?.data);

          case 404:
            throw new Error(error.response?.data);

          case 409:
            throw new Error(error.response?.data);

          default:
            throw new Error(defaultMsg);
        }
      }

      throw new Error(error);
    }
  };

  read = async (uuid) => {
    try {
      const response = await customAxios.get(`${USER_API}/${uuid}`);

      if (!response) new Error('Não foi possível carregar usuário');

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          case 404:
            throw new Error(error.response?.data);

          default:
            throw new Error('Não foi possível carregar usuário');
        }
      }

      throw new Error(error);
    }
  };

  list = async (filter = '') => {
    try {
      const response = await customAxios.get(`${USERS_API}${filter}`);

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          case 404:
            throw new Error(error.response?.data);

          default:
            throw new Error('Não foi possível listar usuários');
        }
      }

      throw new Error(error);
    }
  };

  edit = async ({
    uuid,
    role,
    name,
    phone,
    email,
    emergency_contact,
    professional_role,
    admissiondate,
    rpa_number_registration,
    cep,
    address,
    number,
    complement,
    neighborhood,
    city,
    state,
    birthdate,
    status,
    document,
    supervisor_uuids,
  }) => {
    try {
      const response = await customAxios.put(`${USER_API}/${uuid}`, {
        role,
        name,
        phone,
        email,
        emergency_contact,
        professional_role,
        admissiondate,
        rpa_number_registration,
        cep,
        address,
        number,
        complement,
        neighborhood,
        city,
        state,
        birthdate,
        status,
        document,
        supervisor_uuids,
      });

      if (!response) new Error('Não foi possível editar usuário');

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response.status;
        const defaultMsg = 'Não foi possível editar usuário';
        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          case 403:
            throw new Error(error.response?.data);

          case 404:
            throw new Error(error.response?.data);

          case 409:
            throw new Error(error.response?.data);

          case 422:
            throw new Error(errorMsgHttp(error.response?.data, defaultMsg));

          default:
            throw new Error(defaultMsg);
        }
      }

      throw new Error(error);
    }
  };

  updateStatus = async ({ uuid, status }) => {
    try {
      const response = await customAxios.patch(`${USER_API}/${uuid}`, {
        status,
      });

      if (!response) new Error('Não foi possível editar usuário');

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;
        const defaultMsg = 'Não foi possível editar usuário';
        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          case 404:
            throw new Error(errorMsgHttp(error.response?.data, defaultMsg));

          case 422:
            throw new Error(errorMsgHttp(error.response?.data, defaultMsg));

          default:
            throw new Error(defaultMsg);
        }
      }

      throw new Error(error);
    }
  };

  delete = async (uuid) => {
    try {
      const response = await customAxios.delete(`${USER_API}/${uuid}`);

      if (!response) new Error('Não foi possível apagar usuário');

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response.status;
        const defaultMsg = 'Não foi possível apagar usuário';

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          case 422:
            throw new Error(error.response?.data);

          default:
            throw new Error(defaultMsg);
        }
      }

      throw new Error(error);
    }
  };

  listTemplate = async (filter = '') => {
    try {
      const response = await customAxios.get(`${USER_API}/template${filter}`);

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          case 404:
            throw new Error(error.response?.data);

          default:
            throw new Error('Não foi possível listar usuários');
        }
      }

      throw new Error(error);
    }
  };

  getTherapistSupervisors = async (filter = '') => {
    try {
      const response = await customAxios.get(
        `${USERS_API}/therapist-supervisors${filter}`
      );

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          case 404:
            throw new Error(error.response?.data);

          default:
            throw new Error('Não foi possível listar usuários');
        }
      }

      throw new Error(error);
    }
  };

  listAllTherapists = async (filter = '') => {
    try {
      const response = await customAxios.get(
        `${USERS_API}/list-therapists${filter}`
      );

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          case 404:
            throw new Error(error.response?.data);

          default:
            throw new Error('Não foi possível listar usuários');
        }
      }

      throw new Error(error);
    }
  };
}

export default new UsersService();
